import axios from 'axios';

const api = axios.create({
  baseURL: 'https://admin.waycom360.com/api', // Defina a URL da API aqui
  headers: {
    'Content-Type': 'application/json',
    // Adicione qualquer outro cabeçalho necessário, como autenticação se for o caso
  }
});

// Intercepta requisições para adicionar o token automaticamente
api.interceptors.request.use((config) => {
  const token = localStorage.getItem('token');
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
}, (error) => {
  return Promise.reject(error);
});

export default api;