import React from 'react';
import AppRouter from './router'; // Importa o arquivo de roteamento
import './styles/App.scss'; // Importa o arquivo App.scss

function App() {
  return (
    <div>
      <AppRouter /> {/* Vincula o roteamento à aplicação */}
    </div>
  );
}

export default App;
