import React from 'react';

function LoginForm({ email, password, onEmailChange, onPasswordChange, onSubmit, error }) {
  return (
    <div className="login-page">
      <a href="/">
        <svg width="250" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 79 25">
          <path fill="#6D3AEF" d="M66.06,17.9l-14.55-14.49s-.01-.02-.02-.02c-.1-.09-.23-.15-.37-.15s-.27.05-.37.15l-.02.02-14.53,14.47-.04.05c-.08.09-.14.22-.14.35,0,.3.24.54.54.54h15.24c.3,0,.54-.24.54-.54,0-.14-.06-.27-.15-.37,0,0,0,0-.01-.01l-1.23-1.23s0,0,0-.01l-.09-.09s0,0-.01,0c-.14-.12-.32-.19-.52-.19h-7.84c-.3,0-.54-.24-.54-.53,0-.15.06-.28.16-.38l5.16-5.14.02-.02c.1-.09.22-.15.37-.15s.28.06.38.16h0s8.28,8.25,8.28,8.25l.04.04c.15.13.34.21.55.21h8.79c.3,0,.54-.24.54-.54,0-.15-.06-.28-.16-.38h0Z" />
          <path fill="#6D3AEF" d="M78.56,17.91s-.01-.02-.02-.02l-6.34-6.35s-.01-.01-.02-.02c-.09-.1-.14-.22-.14-.36s.05-.26.13-.35l.04-.04,6.64-6.61c.1-.1.16-.23.16-.38,0-.29-.24-.53-.54-.53h-1.83c-.22,0-.42.09-.57.23l-.02.02-5.55,5.53s0,0-.01.01c-.01.02-.03.03-.04.04-.1.09-.22.14-.36.14s-.26-.05-.36-.14c-.02-.01-.03-.03-.04-.04,0,0,0,0-.01-.01l-5.51-5.48s-.07-.08-.11-.11c-.14-.12-.32-.19-.52-.19h-8.79c-.3,0-.54.24-.54.53,0,.15.06.28.16.38l14.44,14.43c.15.15.35.24.58.24h8.79c.3,0,.54-.24.54-.53,0-.14-.05-.27-.15-.37h0Z" />
          <path fill="#6D3AEF" d="M47.51,3.24h-1.84c-.21,0-.4.08-.54.21-.03.02-.05.05-.07.07,0,0,0,0-.01.01l-8.23,8.2-.02.02c-.1.09-.23.15-.37.15s-.27-.06-.37-.15l-.02-.02L27.91,3.65s0,0,0,0c-.02-.02-.04-.05-.06-.07h0c-.09-.08-.21-.12-.34-.12-.14,0-.27.05-.36.14-.01.01-.02.02-.03.03l-8.14,8.09s-.02.02-.03.03c-.1.09-.22.14-.36.14s-.27-.05-.36-.14c-.01-.01-.03-.02-.04-.04L9.9,3.48c-.15-.15-.35-.24-.58-.24H.55s-.01,0-.02,0-.01,0-.02,0c-.29,0-.52.24-.52.53,0,.14.05.26.14.36l.04.04,14.54,14.49h0c.1.1.23.16.38.16s.28-.06.38-.16l8.16-8.13c.1-.1.23-.16.38-.16s.28.06.38.16l8.12,8.1s0,0,0,0l.07.07h0c.09.08.21.12.34.12s.25-.05.34-.12c0,0,0,0,.01,0l.05-.05s0,0,0-.01c0,0,0,0,0,0l14.53-14.46s.01,0,.01-.01c.09-.1.15-.23.15-.37,0-.29-.24-.53-.54-.53h0Z" />
          <path fill="#5DFFC5" d="M30.16.96c-.09.39-.27.7-.46,1.02-.16.26-.33.5-.51.73.1-.13.19-.27.27-.39.03-.04.05-.08.07-.12.02-.04.04-.07.06-.11,0-.01.01-.02.02-.03,0-.01.01-.02.02-.03,0,0,0,0,0-.01.02-.03.03-.06.04-.09.02-.04.04-.08.06-.12.04-.08.07-.16.09-.23.01-.03.02-.06.03-.08,0,0,0,0,0-.01,0-.02,0-.03.01-.05.13-.45.07-.79-.12-.93,0,0,0,0,0,0-.02-.02-.03-.02-.06-.03-.06-.01-.11-.01-.16,0h0s-.09.01-.14.02c-.33.08-.64.23-.93.39-.05.03-.1.06-.15.09-.01,0-.03.02-.04.02h0c-.06.04-.12.07-.18.11-.17.1-.34.21-.51.32-1.07.72-2.08,1.52-3.07,2.34-.08.07-.17.14-.25.21-3.47,2.95-6.75,6.12-9.82,9.47-.55.62-2.19,2.43-3.16,3.88-.06.09-.11.17-.16.25-.03.06-.07.11-.1.16-.32.54-.51,1-.47,1.29,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,.1.42.93.49,2.91-.89-.39.3-1.34,1.04-2.16,1.32-.08.03-.16.05-.23.07-.11.02-.21.04-.31.04-.23,0-.43-.07-.58-.24-.08-.08-.13-.18-.16-.29h0c-.04-.13-.05-.28-.04-.43,0-.02,0-.04,0-.07h0c.01-.11.03-.23.06-.35.02-.07.04-.14.06-.21.03-.09.06-.18.1-.27.1-.26.22-.5.29-.66.49-.98,1.09-1.88,1.72-2.75,1.05-1.44,2.2-2.8,3.4-4.1.69-.73,1.39-1.46,2.1-2.16h0c1.87-1.84,3.86-3.56,5.98-5.12.15-.11.29-.21.44-.32.07-.05.15-.11.22-.16.73-.5,2.63-1.8,4.08-2.28.06-.02.13-.04.19-.06,0,0,0,0,0,0,.06-.02.11-.03.17-.04.21-.05.41-.08.58-.08h0c.15,0,.28.02.4.07.4.17.48.56.4.9h0Z" />
          <path fill="#5DFFC5" d="M13.47,18.14s-.02.01-.03.02c-1.99,1.38-2.81,1.31-2.91.89,0,0,0,0,0,0,.03.02.06.03.1.04.3.05.59-.04.86-.12.69-.21,1.33-.53,1.97-.84h0Z" />
          <path fill="#5DFFC5" d="M29.46,2.32c-.08.13-.17.26-.27.39-.03.05-.07.09-.11.14.15-.19.27-.36.37-.53h0Z" />
          <path fill="#5DFFC5" d="M29.86,1.45c.1-.39.05-.69-.1-.91,0,0-.01-.02-.02-.02.19.14.24.48.12.93h0Z" />
          <path fill="#51D6A3" d="M29.86,1.45s0,.03-.01.05c0,0,0,0,0,.01,0,.03-.02.05-.03.08-.03.07-.05.15-.09.23-.02.04-.04.08-.06.12-.01.03-.03.06-.04.09,0,0,0,0,0,.01,0,.01-.01.02-.02.03,0,.01-.01.02-.02.03-.02.04-.04.07-.06.11-.02.04-.04.08-.07.12-.08.13-.17.26-.27.39-.03.05-.07.09-.11.14.16-.33.52-1.06.66-1.64,0,0,0,0,0-.01.06-.27.06-.53-.01-.69,0,0,0,0,0,0,.19.14.24.48.12.93h0Z" />
          <path fill="#51D6A3" d="M13.47,18.14s-.02.01-.03.02c-1.99,1.38-2.81,1.31-2.91.89,0,0,0,0,0,0,.03.02.06.03.1.04.3.05.59-.04.86-.12.69-.21,1.33-.53,1.97-.84h0Z" />
          <path fill="#495C74" d="M39.41,23.27c-.43.46-.92.7-1.47.7-.97,0-1.69-.67-1.69-1.57s.72-1.56,1.69-1.56c.54,0,1.06.24,1.47.69l-.38.28c-.32-.35-.68-.52-1.08-.52-.67,0-1.18.48-1.18,1.11s.51,1.12,1.18,1.12c.41,0,.76-.17,1.09-.53l.37.28h0Z" />
          <path fill="#495C74" d="M40.9,23.96c-.82,0-1.34-.56-1.34-1.23s.52-1.24,1.34-1.24,1.34.57,1.34,1.24-.52,1.23-1.34,1.23h0ZM40.9,21.9c-.51,0-.84.37-.84.82s.33.82.84.82.85-.37.85-.82-.33-.82-.84-.82Z" />
          <path fill="#495C74" d="M45.77,22.53c0-.45-.18-.63-.51-.63s-.58.23-.58.57v1.46h-.49v-1.45c0-.35-.19-.57-.51-.57s-.57.22-.57.63v1.4h-.49v-2.41h.49v.35c.13-.24.36-.39.7-.39s.6.18.7.47c.14-.28.45-.47.84-.47.55,0,.9.37.9,1.05v1.4h-.49v-1.4h0Z" />
          <path fill="#495C74" d="M46.74,22.75v-1.24h.5v1.22c0,.53.18.8.64.8s.73-.33.73-.8v-1.22h.49v2.41h-.49v-.43c-.16.26-.44.46-.84.46-.71,0-1.02-.42-1.02-1.2h0Z" />
          <path fill="#495C74" d="M51.48,22.7c0-.53-.18-.8-.64-.8s-.73.33-.73.8v1.22h-.49v-2.41h.49v.43c.15-.25.44-.46.84-.46.71,0,1.02.42,1.02,1.2v1.24h-.5v-1.22h0Z" />
          <path fill="#495C74" d="M52.44,20.82c0-.16.14-.27.32-.27s.32.11.32.27-.14.27-.32.27-.32-.11-.32-.27ZM52.51,21.52h.49v2.41h-.49v-2.41Z" />
          <path fill="#495C74" d="M54.71,23.97c-.78,0-1.3-.56-1.3-1.24s.53-1.24,1.3-1.24c.41,0,.78.11,1.2.55l-.34.26c-.31-.32-.56-.4-.82-.4-.5,0-.84.36-.84.82s.34.82.84.82c.27,0,.52-.08.84-.42l.35.27c-.43.45-.81.57-1.22.57Z" />
          <path fill="#495C74" d="M56.08,22.72c0-.71.52-1.24,1.21-1.24.42,0,.8.2.97.51v-.48s.48,0,.48,0v2.41h-.49v-.49c-.17.32-.55.52-.97.52-.69,0-1.21-.53-1.21-1.24ZM58.29,22.71c0-.46-.37-.81-.85-.81s-.85.35-.85.81.37.83.85.83.85-.36.85-.83Z" />
          <path fill="#495C74" d="M60.45,23.97c-.78,0-1.3-.56-1.3-1.24s.53-1.24,1.3-1.24c.41,0,.78.11,1.2.55l-.34.26c-.31-.32-.56-.4-.82-.4-.5,0-.84.36-.84.82s.34.82.84.82c.27,0,.52-.08.84-.42l.35.27c-.43.45-.81.57-1.22.57ZM60.71,24.32l-.43.68-.34-.21.49-.65.28.17h0Z" />
          <path fill="#495C74" d="M61.82,22.72c0-.71.52-1.24,1.21-1.24.42,0,.8.2.97.51v-.48s.48,0,.48,0v2.41h-.49v-.49c-.17.32-.55.52-.97.52-.69,0-1.21-.53-1.21-1.24ZM64.03,22.71c0-.46-.37-.81-.85-.81s-.85.35-.85.81.37.83.85.83.85-.36.85-.83ZM62.63,21.26l-.14-.34c.44-.47.78.19,1.24-.2l.13.33c-.48.46-.79-.19-1.23.21Z" />
          <path fill="#495C74" d="M66.22,23.96c-.82,0-1.34-.56-1.34-1.23s.52-1.24,1.34-1.24,1.34.57,1.34,1.24-.52,1.23-1.34,1.23h0ZM66.22,21.9c-.51,0-.84.37-.84.82s.33.82.84.82.85-.37.85-.82-.33-.82-.84-.82Z" />
          <path fill="#495C74" d="M68.7,23.28l.64-.35c.11.2.26.31.43.31.21,0,.34-.13.34-.32,0-.25-.18-.35-.57-.35h-.43l.58-.93h-.82s0-.74,0-.74h2.02l-.74,1.12c.48.07.77.4.77.93,0,.64-.45,1.05-1.13,1.05-.49,0-.9-.31-1.1-.71h0Z" />
          <path fill="#495C74" d="M71.13,22.92c0-.24.12-.56.35-.9l.81-1.21.76.27-.63.85c.69.05,1.03.46,1.03.99,0,.57-.43,1.04-1.16,1.04s-1.16-.47-1.16-1.04h0ZM72.29,23.28c.24,0,.38-.16.38-.37,0-.18-.13-.36-.37-.36s-.38.17-.38.36.14.37.37.37Z" />
          <path fill="#495C74" d="M74.87,23.97c-.61,0-1.1-.41-1.1-1.03v-1.08c0-.63.48-1.03,1.1-1.03s1.1.41,1.1,1.03v1.09c0,.62-.48,1.03-1.1,1.03h0ZM74.87,21.58c-.14,0-.25.11-.25.25v1.12c0,.15.1.25.25.25s.25-.1.25-.25v-1.12c0-.15-.1-.25-.25-.25Z" />
          <path fill="#495C74" d="M77.46,20.83c-.76,0-1.22.44-1.22,1s.45,1.01,1.21,1.01,1.22-.45,1.22-1-.46-1.01-1.21-1.01h0ZM77.46,22.22c-.33,0-.5-.19-.5-.4s.16-.39.5-.39.49.18.49.4c0,.2-.16.39-.49.39h0Z" />
        </svg>
      </a>
      {error && <p style={{ color: 'red' }}>{error}</p>}
      <form className="login-form" onSubmit={onSubmit}>
        <label>Email</label>
        <input type="email" placeholder="Email" value={email} onChange={onEmailChange} />
        <label>Senha</label>
        <input type="password" placeholder="Senha" value={password} onChange={onPasswordChange} />
        <button type="submit">Entrar</button>
      </form>
    </div>
  );
}

export default LoginForm;
