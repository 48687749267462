import React from 'react';
import { useNavigate } from 'react-router-dom';
import api from '../api/api';  // Importa a configuração do Axios

function Logout() {
  const navigate = useNavigate();

  const handleLogout = async () => {
    try {
      // Faz a requisição de logout para o backend
      await api.post('/wayuser/logout');
      
      // Remove o token do localStorage
      localStorage.removeItem('token');

      // Redireciona o usuário para a página de login
      navigate('/login');
    } catch (error) {
      console.error('Erro ao fazer logout:', error);
    }
  };

  return (
    <button className="btn-logout" onClick={handleLogout}>Sair</button>
  );
}

export default Logout;
