  import React, { useState, useEffect } from 'react';
  import api from '../api/api'; // Importa o Axios configurado
  import Navbar from '../components/Navbar';
  import Filters from '../components/Filters';
  import PostCard from '../components/PostCard';


  function PostList() {
    const [client, setClient] = useState(null); // Estado para armazenar os dados do cliente
    const [posts, setPosts] = useState([]);
    const [filteredPosts, setFilteredPosts] = useState([]);

    // Função para buscar posts e cliente da API
    useEffect(() => {
      const fetchClientData = async () => {
        try {
          const response = await api.get('/wayuser/posts'); // Faz a requisição para a API
          const clientData = response.data; // Acessa os dados do cliente
          setClient(clientData); // Armazena os dados do cliente (logo, background)
          setPosts(clientData.posts); // Armazena os posts
          setFilteredPosts(clientData.posts); // Inicializa os posts filtrados com todos os posts
        } catch (error) {
          console.error('Error fetching posts and client data:', error);
        }
      };

      fetchClientData();
    }, []);
    
    const [isAscending, setIsAscending] = useState(false); 
    const filterPosts = (type) => {
      if (type === 'urgent') {
        setFilteredPosts([...posts].sort((a, b) => (a.tag === 'urgente' ? -1 : 1)));
      } else if (type === 'late') {
        const currentDate = new Date();
        setFilteredPosts([...posts].sort((a, b) => (new Date(a.scheduled) < currentDate ? -1 : 1)));
      } else if (type === 'date') {
        // Ordena por data, alternando entre crescente e decrescente
        setFilteredPosts(prevPosts =>
          [...prevPosts].sort((a, b) =>
            isAscending
              ? new Date(a.scheduled) - new Date(b.scheduled) // Crescente
              : new Date(b.scheduled) - new Date(a.scheduled) // Decrescente
          )
        );
        setIsAscending(!isAscending); // Inverte a ordem na próxima vez que clicar
      }
    };


    // Se os dados do cliente ainda não foram carregados, exibe uma mensagem de carregamento
    if (!client) {
      return (        
        <div className="loader-wrapper">
          <span class="loader"></span>
        </div>
      );
    }

    return (
      <div className="wrapper">
        <header style={{ backgroundImage: `url(${client.profile_banner_path})`, backgroundSize: 'cover' }}>
        {/* Navbar */}
          <Navbar clientLogo={client.profile_logo_path}  />
        </header>
        <main>
          {/* Filters */}
          <Filters filterPosts={filterPosts} isAscending={isAscending} />
          <section className="posts-list">
            {filteredPosts.map(post => (
              <PostCard key={post.id} post={post} />
            ))}
          </section>
        </main>
      </div>
    );
  }

  export default PostList;
