import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';  // Para redirecionar o usuário
import LoginForm from './LoginForm';
import api from '../api/api';

function Login() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(null);
  const navigate = useNavigate();  // Hook do React Router para redirecionar

  // Verificar se o usuário já está logado quando o componente carrega
  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      // Se o usuário estiver logado, redirecione para a página de posts
      navigate('/posts');
    }
  }, [navigate]);

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      const response = await api.post('/wayuser/login', {
        email,
        password,
      });

      // Salva o token no localStorage
      localStorage.setItem('token', response.data.token);
      // Redireciona para a página de postagens após o login
      navigate('/posts');
    } catch (err) {
      setError('Falha no login. Verifique suas credenciais.');
    }
  };

  return (
    <LoginForm
      email={email}
      password={password}
      onEmailChange={(e) => setEmail(e.target.value)}
      onPasswordChange={(e) => setPassword(e.target.value)}
      onSubmit={handleLogin}
      error={error}
    />
  );
}

export default Login;
