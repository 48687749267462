import React, { useState } from 'react';

function ModalDisapprove({ isOpen, submitDisapprove, closeModal }) {
  const [observations, setObservations] = useState('');
  const [attachments, setAttachments] = useState([]);

  if (!isOpen) return null; // Não renderiza o modal se não estiver aberto 

  // Função para adicionar arquivos anexados
  const handleAttachment = (e) => {
    const newFiles = Array.from(e.target.files); // Converte a FileList para array
    setAttachments((prevFiles) => [...prevFiles, ...newFiles]); // Adiciona os novos arquivos à lista de anexos
  };

  // Função para remover um arquivo anexado da lista
  const removeAttachment = (indexToRemove) => {
    setAttachments((prevFiles) =>
      prevFiles.filter((_, index) => index !== indexToRemove)
    );
  };

  return (
    <aside className="modal-disapprove">
      <div className="modal-disapprove-wrapper">
        <div className="modal-disapprove-title">
          <svg width="43" height="34" viewBox="0 0 43 34" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M31.3614 4.90303H6.52613C3.11128 4.90303 0.317322 7.69699 0.317322 11.1118V21.4598C0.317322 24.8747 3.11128 27.6686 6.52613 27.6686H9.63053C10.7688 27.6686 11.7001 28.6 11.7001 29.7383V30.6696C11.7001 33.0496 14.2354 34.4983 16.305 33.3601L25.7734 28.0826C26.2391 27.8239 26.7565 27.6686 27.2739 27.6686H31.3614C34.7762 27.6686 37.5702 24.8747 37.5702 21.4598V11.1118C37.5702 7.69699 34.7762 4.90303 31.3614 4.90303ZM10.6653 18.8728C9.52705 18.8728 8.59573 17.9415 8.59573 16.8032C8.59573 15.665 9.52705 14.7336 10.6653 14.7336C11.8036 14.7336 12.7349 15.665 12.7349 16.8032C12.7349 17.9415 11.8036 18.8728 10.6653 18.8728ZM18.9437 18.8728C17.8055 18.8728 16.8741 17.9415 16.8741 16.8032C16.8741 15.665 17.8055 14.7336 18.9437 14.7336C20.082 14.7336 21.0133 15.665 21.0133 16.8032C21.0133 17.9415 20.082 18.8728 18.9437 18.8728ZM27.2221 18.8728C26.0839 18.8728 25.1525 17.9415 25.1525 16.8032C25.1525 15.665 26.0839 14.7336 27.2221 14.7336C28.3604 14.7336 29.2917 15.665 29.2917 16.8032C29.2917 17.9415 28.3604 18.8728 27.2221 18.8728Z" fill="#6D3AEF" />
            <path d="M36.0179 13.1814C39.447 13.1814 42.2268 10.4017 42.2268 6.97263C42.2268 3.5436 39.447 0.763824 36.0179 0.763824C32.5889 0.763824 29.8091 3.5436 29.8091 6.97263C29.8091 10.4017 32.5889 13.1814 36.0179 13.1814Z" fill="#5DFFC5" />
          </svg>
          <h3>Escreva abaixo suas observações!</h3>
        </div>
        <textarea value={observations} onChange={(e) => setObservations(e.target.value)} placeholder="Digite suas observações..." />
        <div className="modal-disapprove-attachments">
          {attachments.length > 0 && (
            <ul className="attachments-list">
              {attachments.map((file, index) => (
                <li key={index}>
                  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clipPath="url(#clip0_13_158)">
                      <path fillRule="evenodd" clipRule="evenodd" d="M4.09983 0H9.4512L13.984 4.72896V13.9163C13.984 15.0664 13.0503 16 11.9002 16H4.09983C2.94973 16 2.01611 15.0664 2.01611 13.9163V2.08372C2.01611 0.933613 2.94973 0 4.09983 0Z" fill="#BFBDC3" />
                      <path opacity="0.302" fillRule="evenodd" clipRule="evenodd" d="M9.44446 0V4.68836H13.984L9.44446 0Z" fill="white" />
                      <path fillRule="evenodd" clipRule="evenodd" d="M10.033 7.99661H5.96706C5.66263 7.99661 5.41229 8.24693 5.41229 8.55138V11.1357C5.41229 11.4402 5.66261 11.6905 5.96706 11.6905H10.033C10.3374 11.6905 10.581 11.4402 10.581 11.1357V8.55136C10.581 8.24693 10.3375 7.99661 10.033 7.99661ZM7.07657 8.69344C7.40807 8.69344 7.67191 8.96405 7.67191 9.28878C7.67191 9.62028 7.40807 9.89089 7.07657 9.89089C6.74507 9.89089 6.47446 9.62028 6.47446 9.28878C6.47446 8.96405 6.74507 8.69344 7.07657 8.69344ZM10.2157 11.1357C10.2157 11.2372 10.1345 11.3251 10.033 11.3251H5.96706C5.86558 11.3251 5.78439 11.2372 5.78439 11.1357V11.0275L6.52181 10.2901L7.13069 10.899C7.2051 10.9734 7.32011 10.9734 7.39453 10.899L8.9235 9.36998L10.2157 10.6622V11.1357Z" fill="white" />
                    </g>
                    <defs>
                      <clipPath id="clip0_13_158">
                        <rect width="16" height="16" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                  {file.name}
                  <button onClick={() => removeAttachment(index)}>
                    <svg width="11" height="10" viewBox="0 0 11 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path fillRule="evenodd" clipRule="evenodd" d="M0.792893 0.292893C1.18342 -0.0976311 1.81658 -0.0976311 2.20711 0.292893L5.5 3.58579L8.79289 0.292893C9.18342 -0.0976311 9.81658 -0.0976311 10.2071 0.292893C10.5976 0.683417 10.5976 1.31658 10.2071 1.70711L6.91421 5L10.2071 8.29289C10.5976 8.68342 10.5976 9.31658 10.2071 9.70711C9.81658 10.0976 9.18342 10.0976 8.79289 9.70711L5.5 6.41421L2.20711 9.70711C1.81658 10.0976 1.18342 10.0976 0.792893 9.70711C0.402369 9.31658 0.402369 8.68342 0.792893 8.29289L4.08579 5L0.792893 1.70711C0.402369 1.31658 0.402369 0.683417 0.792893 0.292893Z" fill="#6D3AEF" />
                    </svg>
                  </button>
                </li>
              ))}
            </ul>
          )}
        </div>
        <div className="modal-disapprove-actions">
          <input className="modal-disapprove-attach" type="file" multiple onChange={handleAttachment} />
          <button className="modal-disapprove-clip">
            <svg width="26" height="25" viewBox="0 0 26 25" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M23.5033 3.32706C20.3693 0.173371 15.2697 0.173371 12.1352 3.32706L2.39089 13.1323C0.152378 15.3851 0.152526 19.0505 2.39118 21.3031C3.51061 22.4296 4.98055 22.9927 6.45108 22.9926C7.92122 22.9924 9.39195 22.4294 10.5112 21.3031L19.4432 12.315C20.0939 11.6603 20.4524 10.7897 20.4525 9.86379C20.4525 8.9378 20.0942 8.06732 19.4434 7.41241C18.1 6.06082 15.9143 6.06087 14.5713 7.41266L8.73006 13.2903C8.28161 13.7415 8.28161 14.4731 8.72996 14.9244C9.17836 15.3758 9.90547 15.3756 10.354 14.9244L16.1952 9.04678C16.643 8.59621 17.3714 8.59612 17.8192 9.04668C18.0361 9.26492 18.1556 9.55511 18.1556 9.86374C18.1556 10.1724 18.0362 10.4625 17.8192 10.6808L8.88717 19.6689C7.54389 21.0204 5.35837 21.0206 4.01519 19.669C2.67201 18.3173 2.67191 16.118 4.01494 14.7663L13.7592 4.96123C15.9981 2.70847 19.6407 2.70847 21.8792 4.96123C22.9638 6.05242 23.5611 7.50334 23.5611 9.04658C23.5611 10.5898 22.9637 12.0407 21.8792 13.132L12.1351 22.9374C11.6867 23.3887 11.6867 24.1203 12.1352 24.5716C12.3594 24.7973 12.6533 24.91 12.9472 24.91C13.2411 24.91 13.5349 24.7972 13.7592 24.5716L23.5032 14.7662C25.0216 13.2385 25.8577 11.2073 25.8578 9.04663C25.8578 6.88604 25.0216 4.85478 23.5033 3.32706Z" fill="#6D3AEF" />
            </svg>
          </button>

          <button className="modal-disapprove-btn" onClick={() => {
            submitDisapprove(observations, attachments); setObservations('');
            setAttachments([]); // Limpa a lista de anexos após o envio
          }}
          >
            Salvar
          </button>
          {/* <button onClick={closeModal}>Salvar</button> */}
        </div>
      </div>
    </aside>
  );
}

export default ModalDisapprove;
