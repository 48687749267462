import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Login from './pages/Login';
import PostList from './pages/PostList';
import PrivateRoute from './components/PrivateRoute';

function AppRouter() {
  const token = localStorage.getItem('token'); // Verifica se o usuário está logado

  return (
    <Router>
      <Routes>
        {/* Rota raiz que redireciona dependendo se o usuário está logado */}
        <Route path="/" element={token ? <Navigate to="/posts" /> : <Navigate to="/login" />} />

        {/* Rota de login */}
        <Route path="/login" element={<Login />} />

        {/* Rota protegida para a lista de postagens */}
        <Route 
          path="/posts" 
          element={
            <PrivateRoute>
              <PostList />
            </PrivateRoute>
          } 
        />
      </Routes>
    </Router>
  );
}

export default AppRouter;
