import React from 'react';
import Logout from './Logout';

function Navbar({ clientLogo }) {
  return (
    <header>
        <img className="logo" src="/images/logo-way.png" alt="Logo Way"/>
        <img className="logo" src={clientLogo} alt="Logo Cliente"/>
      <Logout />
    </header>
  );
}

export default Navbar;
