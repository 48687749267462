import React from 'react';

const Filters = ({ filterPosts, isAscending }) => {
  return (
    <section className="filters">
      <button onClick={() => filterPosts('urgent')}>Urgentes</button>
      <button onClick={() => filterPosts('late')}>Atrasadas</button>
      <button onClick={() => filterPosts('date')} className="date-sort-button">
        Data
        <span className="arrows">
          {/* Seta para cima (ativa quando isAscending for true) */}
          <svg className={`arrow-up ${isAscending ? 'active' : ''}`} width="10" viewBox="0 0 8 6" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M3.18912 1.1236C3.58825 0.570536 4.41175 0.570536 4.81088 1.12359L7.1861 4.41479C7.6634 5.07616 7.19083 6 6.37522 6L1.62478 6C0.809174 6 0.336598 5.07616 0.813896 4.41479L3.18912 1.1236Z" />
          </svg>
          {/* Seta para baixo (ativa quando isAscending for false) */}
          <svg className={`arrow-down ${!isAscending ? 'active' : ''}`} width="10" viewBox="0 0 8 6" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M4.81088 4.8764C4.41175 5.42946 3.58825 5.42946 3.18911 4.8764L0.813897 1.58521C0.336598 0.923842 0.809173 2.37609e-07 1.62478 3.08912e-07L6.37522 7.24208e-07C7.19083 7.95511e-07 7.6634 0.923842 7.1861 1.58521L4.81088 4.8764Z"/>
          </svg>
        </span>
      </button>
    </section>
  );
}

export default Filters;
